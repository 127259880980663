import Http from '@/ship/Http';
import { AxiosResponse } from 'axios';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import { ISubmitDrawingData } from '@/ship/Requests/ISubmitDrawingDataRequest';

// Drawings

export const submitDrawingFile = async (drawing: File): Promise<number> => {
    const formData = new FormData();
    formData.append('file', drawing);

    const response = await Http.post<void, AxiosResponse<ITransformedValue<{ id: number }>>>(
        `/drawings/file`,
        formData,
    );

    return response.data.data.id;
};

export const submitDrawingData = (fileId: number, data: ISubmitDrawingData[]): Promise<void> => {
    return Http.post(`/drawings`, { fileId, data });
};
